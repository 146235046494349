import React from 'react';

import Main from './pages/Main/Main'

const App = (): JSX.Element => {
    return (
        <Main />
    );
};

export default App;
